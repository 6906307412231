<template>
  <ClientOnly class="relative mx-auto h-[300px] w-[300px]">
    <div ref="target" class="relative mx-auto h-[300px] w-[300px]">
      <Pie :data="dataChart" :options="options" v-if="targetIsVisible" />
      <div
        :class="
          twMerge(
            'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-0 font-sans text-3xl font-bold text-black transition-all duration-300',
            isShowed && 'scale-100',
          )
        "
      >
        <NumberAnimation
          ref="number1"
          :from="0"
          :to="percent"
          :format="(e: number) => e.toFixed(0)"
          :duration="2"
          :delay="5"
          easing="linear"
        />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import { Chart as ChartJS, ArcElement } from 'chart.js'
import { Pie } from 'vue-chartjs'
import { twMerge } from 'tailwind-merge'
//@ts-ignore
import NumberAnimation from 'vue-number-animation'

ChartJS.register(ArcElement)

interface Props {
  percent: number
}
const props = defineProps<Props>()

const target = ref(null)
const targetIsVisible = useElementVisibility(target)
const isShowed = ref(false)

const number1 = ref(null)

const dataChart = {
  datasets: [
    {
      backgroundColor: ['#00B4D2', 'transparent'],
      data: [props.percent, 100 - props.percent],
    },
  ],
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  events: [],
  animation: {
    animateScale: true,
    delay: 700,
    duration: 1000,
    onProgress: () => (isShowed.value = false),
    onComplete: () => {
      isShowed.value = true
      //@ts-ignore
      number1?.value.restart()
    },
  },
}
</script>
